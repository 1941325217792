// Input Fields
.jbl-label {
	width: 100%;
	margin: 0;
	font-size: 12px;
	font-weight: 600;
}

.jbl-radio-label,
.jbl-checkbox-label,
.jbl-toggle-label {
	width: max-content;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
	color: var(--cool-gray-40);
}

.jbl-input,
.jbl-textarea {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 9px 0 9px 12px;
	width: 100%;
	position: relative;
	right: 0;
	top: 0;
	outline: none;
	border-radius: 4px;
	border: 1px solid var(--cool-gray-10);
	background-color: var(--static-white);

	&:disabled {
		background-color: var(--cool-gray-10);
	}
}

.jbl-radio-label,
.jbl-checkbox-label,
.jbl-toggle-label {
	display: flex;
	align-items: center;
}

.jbl-radio,
.jbl-checkbox,
.jbl-toggle {
	&:focus {
		outline: none;
		box-shadow: 0 0 3px rgb(0 0 0 / 25%);
	}

	&:checked:focus,
	&:checked:focus {
		box-shadow: none;
	}

	&:disabled {
		background-color: var(--cool-gray-10);
	}
}

.jbl-radio,
.jbl-checkbox {
	width: 20px;
	height: 20px;
	margin: 0 4px 0 0;
	appearance: none;
	border: 1px solid var(--cool-gray-10);
	background-color: var(--static-white);
}

.jbl-radio {
	border-radius: 50%;

	&:checked:after {
		content: '';
		width: 14px;
		height: 14px;
		background: var(--blue-90);
		display: block;
		border-radius: 50%;
		position: relative;
		left: 2px;
		top: 2px;
	}
}

.jbl-checkbox {
	position: relative;

	&:checked:before,
	&:checked:after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
	}

	&:checked:before {
		background-color: var(--blue-90);
	}

	&:checked:after {
		position: absolute;
		top: 0;
		left: 0;
		background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='18px' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
		background-repeat: no-repeat;
	}

	&:checked:focus,
	&:focus {
		outline: none !important;
	}
}

.jbl-toggle {
	width: 34px;
	height: 20px;
	margin: 0 4px 0 0;
	appearance: none;
	border-radius: 10px;
	border: 1px solid var(--cool-gray-10);
	background-color: var(--cool-gray-10);
	transition: all 0.3s ease-in-out;

	&:after {
		content: '';
		width: 14px;
		height: 14px;
		background-color: var(--static-white);
		display: block;
		border-radius: 50%;
		position: relative;
		left: 2px;
		top: 2px;
		transition: all 0.3s ease-in-out;
	}

	&:checked {
		background-color: var(--blue-90);
		transition: all 0.3s ease-in-out;

		&:after {
			transform: translateX(14px);
			transition: all 0.3s ease-in-out;
		}
	}
}

// Input Validation
.jbl-required {
	margin-left: 2px;
	color: var(--red-60);
}

.jbl-has-error {
	color: var(--red-60) !important;
}

.jbl-input-has-error {
	border-color: var(--red-60);
}
