@use 'sass:math';

// -------------------------- Mixins --------------------------

@mixin jbl-title(
	$fontWeight: 400,
	$fontSize: 16px,
	$lineHeight: 1.2,
	$textTransform: none,
	$color: var(--cool-gray-40)
) {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: $fontWeight;
	font-size: $fontSize;
	line-height: $lineHeight;
	text-transform: $textTransform;
	color: $color;
}

// -------------------------- Headings --------------------------
@for $i from 1 through 8 {
	$size: if(
		$i == 1,
		34px,
		if(
			$i == 2,
			28px,
			if($i == 3, 22px, if($i == 4, 18px, if($i == 5, 16px, if($i == 6, 14px, if($i == 7, 12px, 10px)))))
		)
	);

	.jbl-heading-#{$i} {
		@include jbl-title(500, $size, $size * 1.2, none);
	}

	.jbl-heading-u-#{$i} {
		@include jbl-title(500, $size, $size * 1.2, uppercase);
	}

	.jbl-heading-b-#{$i} {
		@include jbl-title(bold, $size, $size * 1.2, none);
	}

	.jbl-heading-b-u-#{$i} {
		@include jbl-title(bold, $size, $size * 1.2, uppercase);
	}
}

// -------------------------- Titles --------------------------
.jbl-header-label,
.jbl-toolbar-label {
	@include jbl-title($fontWeight: 700, $fontSize: 14px, $lineHeight: 20px, $textTransform: uppercase);
	cursor: pointer;
	transition: all 0.2s ease-in-out;

	&:hover {
		color: var(--static-white);
	}
}

.jbl-toolbar-label {
	@include jbl-title($fontWeight: 700, $fontSize: 12px, $lineHeight: 19px);
	&:hover {
		background-color: var(--cool-gray-10);
	}
}

.jbl-input-label {
	@include jbl-title($fontWeight: 700, $fontSize: 12px, $lineHeight: 17px);
}

.jbl-input-text {
	@include jbl-title($fontWeight: 400, $fontSize: 12px, $lineHeight: 19px);

	&::placeholder,
	&:disabled {
		color: var(--cool-gray-30);
	}
}

// -------------------------- Mixins --------------------------

@for $i from 100 through 900 {
	.jbl-#{$i} {
		font-weight: $i;
	}
}

// -------------------------- Text Transforms --------------------------
$transforms: uppercase, lowercase, capitalize;

@each $transform in $transforms {
	.jbl-#{$transform} {
		text-transform: $transform;
	}
}

// -------------------------- Font Styles --------------------------
$styles: normal, italic, oblique;

@each $style in $styles {
	.jbl-#{$style} {
		font-style: $style;
	}
}

// -------------------------- Text Align --------------------------
$aligns: left, center, right;

@each $align in $aligns {
	.jbl-#{$align} {
		text-align: $align;
	}
}

// -------------------------- Text Decoration --------------------------
$decorations: underline, line-through;

@each $decoration in $decorations {
	.jbl-#{$decoration} {
		text-decoration: $decoration;
	}
}

.jbl-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.jbl-text-muted {
	color: var(--cool-gray-30);
}
