@use 'foundation/styles/flex';

.mi-badge {
	@include flex.jbl-flex(row, center, center);
	padding: 4px 12px;
	font-size: 10px;
	font-weight: 700;

	&--default {
		color: var(--cool-gray-40);
		background-color: var(--cool-gray-00);

		&-cell {
			display: inline;
			padding: 2px 8px;
			border-radius: 10px;
		}
	}

	&--green {
		color: var(--green-80);
		background-color: var(--green-10);

		&-cell {
			display: inline;
			padding: 2px 8px;
			border-radius: 10px;
		}
	}

	&--red {
		color: var(--red-80);
		background-color: var(--red-10);

		&-cell {
			display: inline;
			padding: 2px 8px;
			border-radius: 10px;
		}
	}

	&--yellow {
		color: var(--yellow-80);
		background-color: var(--yellow-10);

		&-cell {
			display: inline;
			padding: 2px 8px;
			border-radius: 10px;
		}
	}
}
