.jbl-dropdown.show {
	display: flex;
	flex-direction: column;
}

.jbl-dropdown {
	min-width: 180px;
	padding: 6px 0;
	border-color: var(--cool-gray-20);
	border-radius: 2px;

	&-divider {
		height: 1px;
		margin: 8px 0;
		overflow: hidden;
		background-color: var(--cool-gray-10);
	}

	&-item,
	&-header {
		font-size: 12px;
		opacity: 0;
		transform-origin: top center;
		animation: rotateX 300ms 20ms ease-in-out forwards;
	}

	&-item {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 6px 12px;
		clear: both;
		white-space: nowrap;
		cursor: pointer;
		color: var(--cool-gray-40);
		transition: all 200ms ease-in-out;

		&:hover {
			background-color: var(--cool-gray-10);
			transition: all 0.2s ease-in-out;
		}

		.disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	&-header {
		padding: 8px 12px;
		font-weight: 600;
		color: var(--cool-gray-30);
		text-transform: uppercase;
	}

	@for $item from 0 through 10 {
		&-item-#{$item} {
			animation: rotateX 300ms ($item * 20ms) ease-in-out forwards;
		}
	}
}
