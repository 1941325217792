.jbl-resize {
	height: 100%;
	width: 4px;
	position: absolute;
	cursor: col-resize;
	z-index: 100;
	right: 0;
}

.jbl-resize-inverted {
	@extend .jbl-resize;
	left: 0;
	right: inherit;
}

.jbl-grid-template {
	margin-top: 48px;
	position: relative;
	top: 0;
}

.jbl-full-screen-page {
	position: absolute;
	top: -48px;
	z-index: 1040;
	width: 100%;
	background-color: var(--static-white);
	height: 100vh;
	margin-top: 0;
	transition: top 0.2s ease-in-out;
	transition: background-color 0.2s ease-in-out;
}

.jbl-none {
	display: none;
}

.jbl-hidden {
	visibility: hidden;
}
