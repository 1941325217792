// Donut fade animations
@keyframes donutfade {
	0% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@keyframes donutfadelong {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Scale in-out animation
@keyframes scaleInOut {
	0% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1.2);
	}
	100% {
		transform: scale(1);
	}
}

// Skeleton loading animation
@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 20%, 70%);
	}
	100% {
		background-color: hsl(200, 20%, 95%);
	}
}

// Fade in-up animation
@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}
	100% {
		opacity: 1;
		transform: none;
	}
}

.jblFadeInUp {
	animation: fadeInUp;
}

// Bounce in-down animation
@keyframes bounceInDown {
	0%,
	60%,
	75%,
	90%,
	100% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: translate3d(0, -3000px, 0);
	}
	60% {
		opacity: 1;
		transform: translate3d(0, 25px, 0);
	}
	75% {
		transform: translate3d(0, -10px, 0);
	}
	90% {
		transform: translate3d(0, 5px, 0);
	}
	100% {
		transform: none;
	}
}

.jblBounceInDown {
	animation: bounceInDown;
}

// Rotating animation
@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.jblRotating {
	animation: rotating;
}

@keyframes jblFadeInDown {
	0% {
		opacity: 0;
		transform: translate3d(0, -10px, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.jblFadeInDown {
	animation-name: jblFadeInDown;
	animation-duration: 0.2s;
}

@keyframes rotateX {
	0% {
		opacity: 0;
		transform: rotateX(-90deg);
	}

	50% {
		transform: rotateX(-20deg);
	}

	100% {
		opacity: 1;
		transform: rotateX(0deg);
	}
}
