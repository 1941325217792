// -------------------------- Bootstrap Overrides --------------------------

.toast {
	--bs-toast-zindex: inherit !important;
	--bs-toast-padding-x: inherit !important;
	--bs-toast-padding-y: inherit !important;
	--bs-toast-spacing: inherit !important;
	--bs-toast-max-width: inherit !important;
	--bs-toast-font-size: inherit !important;
	--bs-toast-color: inherit !important;
	--bs-toast-bg: inherit !important;
	--bs-toast-border-width: inherit !important;
	--bs-toast-border-color: inherit !important;
	--bs-toast-border-radius: inherit !important;
	--bs-toast-box-shadow: inherit !important;
	--bs-toast-header-color: inherit !important;
	--bs-toast-header-bg: inherit !important;
	--bs-toast-header-border-color: inherit !important;
}

.toast-body {
	font-size: inherit !important;
}

.jbl-toast-container {
	position: fixed;
	display: flex;
	flex-direction: column;
	right: 1em;
	top: 1em;
	z-index: 99999999;
}
