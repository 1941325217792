// Hide ag-grid status bar
.hide-ag-grid-status-bar .ag-status-bar {
	display: none;
}

// Single row grid styles
.single-row-grid .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
.single-row-grid .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container {
	min-height: 28px !important;
}

// Selected row styles
.ag-row-selected {
	border-color: var(--cool-gray-10) !important;
}

// Theme-specific styles
.ag-theme-balham {
	.ag-row.ag-row-group > .ag-cell-wrapper {
		padding-left: 11px;
		padding-right: 11px;

		.ag-icon.ag-icon-contracted,
		.ag-icon.ag-icon-expanded {
			cursor: pointer;
		}
	}

	.qn-ag-grid-row-group-white {
		background-color: var(--static-white);
	}
}

// Row hover styles
.ag-root-wrapper-body .ag-row-hover {
	background-color: rgba(227, 241, 255, 0.6);
}

// Selected row overlay styles
.ag-root .qn-ag-grid-row-selected {
	background-color: var(--blue-20);
	box-shadow:
		0 -1px 3px rgba(0, 0, 0, 0.1),
		0 3px 2px rgba(0, 0, 0, 0.1);
	z-index: 1;
}

// Custom cell style
.ag-root .qn-ag-grid-cell-negotype {
	font-weight: 700;
}

// Loading row styles
.ag-row-loading {
	height: 30px !important;
}

// Loading overlay styles
.ag-overlay-wrapper.ag-overlay-loading-wrapper,
.jbl-min-height-grid .ag-overlay-wrapper.ag-overlay-loading-wrapper {
	margin: 100px 0;
	padding: 0 20px;
	height: calc(100% - 200px);

	&.jbl-min-height-grid {
		margin: 30px 0;
		height: calc(100% - 30px);
	}
}

// Custom ag-grid overlay style
.jbl-custom-ag-grid-overlay {
	padding: 10px;
	border: 1px solid #444;
}

.ag-filter-apply-panel-button {
	outline: none !important;
	border: 1px solid var(--cool-gray-40) !important;
	border-radius: 2px !important;
}
