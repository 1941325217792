// -------------------------- Custom Modal Styles --------------------------

.jbl-modal-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px;
	border-radius: 4px;
	background-color: var(--static-white);
}

.jbl-modal-body {
	padding: 12px 20px;
}
