// -------------------------- Mixins --------------------------

@mixin jbl-btn-size($fontSize: 12px, $pdTopBot: 6px, $pdRightLeft: 12px, $minWidth: inherit) {
	padding: $pdTopBot $pdRightLeft;
	min-width: $minWidth;
	font-size: $fontSize;
}

@mixin jbl-btn-color($bg: var(--blue-90), $color: var(--static-white), $borderColor: var(--static-black)) {
	background: $bg;
	color: $color;
	border-color: $borderColor;
	transition: all 0.2s ease-in-out;
}

@mixin jbl-btn-hover($bg: var(--blue-100), $color: var(--static-white), $borderColor: var(--static-black)) {
	&:hover {
		background: $bg;
		color: $color;
		border-color: $borderColor;
		transition: all 0.2s ease-in-out;
	}
}

@mixin jbl-btn-active($bg: var(--blue-100), $color: var(--static-white), $borderColor: var(--static-black)) {
	&:active {
		background: $bg;
		color: $color;
		border-color: $borderColor;
		transition: all 0.2s ease-in-out;
	}
}

@mixin jbl-btn-disabled($bg: var(--blue-90), $color: var(--static-white), $borderColor: var(--static-black)) {
	&:disabled {
		background: $bg;
		color: $color;
		border-color: $borderColor;
		opacity: 0.5;
		transition: all 0.2s ease-in-out;
	}
}

// -------------------------- Common --------------------------
.jbl-btn-combo-common {
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.jbl-btn-combo-dropdown-common {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
	border: 1px solid;
}

// -------------------------- Styles --------------------------
.jbl-btn {
	display: inline-block;
	outline: none;
	border: 1px solid;
	text-align: center;
	border-radius: 3px;
	font-weight: 600;
	cursor: pointer;
	touch-action: manipulation;
	user-select: none;
	white-space: nowrap;
	transition: all 0.2s ease-in-out;

	&:disabled {
		cursor: not-allowed;
	}
}

.jbl-btn-xs {
	@include jbl-btn-size(12px, 4px, 10px);
}

.jbl-btn-sm {
	@include jbl-btn-size();
}

.jbl-btn-md {
	@include jbl-btn-size(14px, 8px, 14px);
}

.jbl-btn-lg {
	@include jbl-btn-size(18px, 12px, 16px);
}

.jbl-btn-primary {
	@include jbl-btn-color();
	@include jbl-btn-hover();
	@include jbl-btn-active();
	@include jbl-btn-disabled();

	.jbl-btn-icon {
		svg {
			fill: var(--static-white);
		}
	}
}

.jbl-btn-secondary {
	@include jbl-btn-color(var(--static-white), var(--cool-gray-40), var(--cool-gray-20));
	@include jbl-btn-hover(var(--cool-gray-00), var(--cool-gray-40), var(--cool-gray-20));
	@include jbl-btn-active(var(--cool-gray-00), var(--cool-gray-40), var(--cool-gray-20));
	@include jbl-btn-disabled(var(--static-white), var(--cool-gray-40), var(--cool-gray-20));

	.jbl-btn-icon {
		svg {
			fill: var(--cool-gray-40);
		}
	}
}

.jbl-btn-link {
	@include jbl-btn-color(transparent, var(--blue-40), transparent);
	@include jbl-btn-hover(transparent, var(--blue-90), transparent);
	@include jbl-btn-active(transparent, var(--blue-90), transparent);
	@include jbl-btn-disabled(transparent, var(--blue-40), transparent);
}

.jbl-btn-warning {
	@include jbl-btn-color(var(--yellow-60), var(--static-white), var(--yellow-80));
	@include jbl-btn-hover(var(--yellow-80), var(--static-white), var(--yellow-80));
	@include jbl-btn-active(var(--yellow-80), var(--static-white), var(--yellow-80));
	@include jbl-btn-disabled(var(--yellow-20), var(--static-white), var(--yellow-60));

	.jbl-btn-icon {
		svg {
			fill: var(--static-white);
		}
	}
}

.jbl-btn-error {
	@include jbl-btn-color(var(--red-60), var(--static-white), var(--red-80));
	@include jbl-btn-hover(var(--red-80), var(--static-white), var(--red-80));
	@include jbl-btn-active(var(--red-80), var(--static-white), var(--red-80));
	@include jbl-btn-disabled(var(--red-20), var(--static-white), var(--red-60));

	.jbl-btn-icon {
		svg {
			fill: var(--static-white);
		}
	}
}

.jbl-btn-success {
	@include jbl-btn-color(var(--green-60), var(--static-white), var(--green-80));
	@include jbl-btn-hover(var(--green-80), var(--static-white), var(--green-80));
	@include jbl-btn-active(var(--green-80), var(--static-white), var(--green-80));
	@include jbl-btn-disabled(var(--green-20), var(--static-white), var(--green-60));

	.jbl-btn-icon {
		svg {
			fill: var(--static-white);
		}
	}
}

.jbl-btn-combo-primary {
	.jbl-btn {
		@extend .jbl-btn-combo-common;
	}

	.jbl-btn-icon {
		margin-right: 5px;

		svg {
			fill: var(--static-white);
		}
	}

	&-dropdown {
		@extend .jbl-btn-combo-dropdown-common;
		@include jbl-btn-color();
		@include jbl-btn-hover();
		@include jbl-btn-active();
		@include jbl-btn-disabled();
		@include jbl-btn-size();

		.jbl-btn-icon {
			top: 1px;
			margin-right: 0;

			svg {
				fill: var(--static-white);
			}
		}
	}
}

.jbl-btn-combo-secondary {
	.jbl-btn {
		@extend .jbl-btn-combo-common;
	}

	.jbl-btn-icon {
		margin-right: 5px;

		svg {
			fill: var(--cool-gray-40);
		}
	}

	&-dropdown {
		@extend .jbl-btn-combo-dropdown-common;
		@include jbl-btn-color(var(--static-white), var(--cool-gray-40), var(--cool-gray-20));
		@include jbl-btn-hover(var(--cool-gray-00), var(--cool-gray-40), var(--cool-gray-20));
		@include jbl-btn-active(var(--cool-gray-00), var(--cool-gray-40), var(--cool-gray-20));
		@include jbl-btn-disabled(var(--static-white), var(--cool-gray-40), var(--cool-gray-20));
		@include jbl-btn-size();

		.jbl-btn-icon {
			top: 1px;
			margin-right: 0;

			svg {
				fill: var(--cool-gray-40);
			}
		}
	}
}

.jbl-btn-icon-transparent {
	display: inline-block;
	margin-bottom: 0;
	line-height: 1.4;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background: none;
	padding: 0;
	height: 32px;
	width: 32px;
	text-transform: uppercase;
	font-size: inherit;
	border-radius: 30px;
	color: var(--static-white);
	transition: background-color 0.3s ease-out;
	border: none;

	&:hover {
		border: none;
		color: var(--static-white);
		background: var(--blue-60);
		transition: background-color 0.3s ease-out;
	}
}
