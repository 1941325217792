@keyframes shimmer {
	0% {
		background-position: -2000px 0;
	}

	100% {
		background-position: 2000px 0;
	}
}

%narrow-y-scrollbar {
	&::-webkit-scrollbar {
		width: 6px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--cool-gray-20);
		border-radius: 10px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 10px;
	}
}

%narrow-y-scrollbar-thin {
	&::-webkit-scrollbar {
		height: 6px;
	}
}
