// -------------------------- NGB Modal --------------------------
ngb-modal-window {
	z-index: 999999 !important;
}

ngb-modal-backdrop {
	z-index: 999998 !important;
	opacity: 0.3 !important;
}

.modal-dialog {
	width: 600px;
	max-width: inherit !important;
}

.modal-content {
	z-index: 9999 !important;
	border: none !important;
	border-radius: 4px !important;
}

// -------------------------- NGB Tooltip --------------------------
.tooltip-inner {
	font-size: 12px !important;
	border-color: transparent !important;
	background-color: rgba(100, 100, 100, 0.9) !important;
}

.tooltip-arrow {
	&::before {
		top: 0 !important;
		border-top-color: rgba(100, 100, 100, 0.9) !important;
	}
}

// -------------------------- NGB Dropdown --------------------------
.dropdown-toggle {
	&::after {
		display: none;
	}
}
