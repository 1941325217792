// Global resets
html,
body {
	height: 100%;
}

body {
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 12px;
	color: var(--cool-gray-40);
	background-color: var(--cool-gray-00);
}

// Heading styles
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: 600;
	line-height: 1.2;
	color: inherit;

	& small {
		font-weight: 600;
		font-size: 85%;
		color: var(--cool-gray-40);
	}
}

// Heading font sizes
h1 {
	font-size: 34px;
}

h2 {
	font-size: 28px;
}

h3 {
	font-size: 22px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 14px;
}

h6 {
	font-size: 12px;
}

// Anchor link styles
a {
	color: var(--blue-40);
	text-decoration: none;
	transition: color 0.12s ease-out;

	&:hover,
	&:focus {
		color: var(--blue-90);
	}

	&:active {
		color: var(--blue-60) !important;
		background-color: transparent !important;
		border-color: transparent !important;
	}
}
