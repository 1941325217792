// -------------------------- Cursors --------------------------
$cursors: pointer, not-allowed, wait, inherit, col-resize, help, text, move, zoom-in, zoom-out;

@each $cursor in $cursors {
	.jbl-crs-#{$cursor} {
		cursor: $cursor;
	}
}

// Define the border radius values in a map
$border-radius-values: (
	initial: initial,
	4: 4px,
	8: 8px,
	16: 16px,
	100: 100%
);

// Define the border positions in a map
$border-positions: (
	full: '',
	tl: top-left,
	tr: top-right,
	bl: bottom-left,
	br: bottom-right
);

@each $radius-key, $radius-value in $border-radius-values {
	@each $position-key, $position-value in $border-positions {
		@if $position-key== 'full' {
			$class-name: 'jbl-br-#{$radius-key}';

			.#{$class-name} {
				border-radius: $radius-value;
			}
		} @else {
			$class-name: 'jbl-br-#{$position-key}-#{$radius-key}';

			.#{$class-name} {
				border-#{$position-value}-radius: $radius-value;
			}
		}
	}
}

// Define the padding values in a list
$margin-padding-values: 0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 36;

// Define the margin/padding directions in a map
$margin-padding-directions: (
	def: '',
	l: left,
	r: right,
	t: top,
	b: bottom
);

@each $padding-value in $margin-padding-values {
	@each $direction-key, $direction-value in $margin-padding-directions {
		@if $direction-key== 'def' {
			$class-name: 'jbl-pd-#{$padding-value}';

			.#{$class-name} {
				padding: $padding-value * 1px;
			}
		} @else {
			$class-name: 'jbl-pd-#{$direction-key}-#{$padding-value}';

			.#{$class-name} {
				padding-#{$direction-value}: $padding-value * 1px;
			}
		}
	}
}

@each $margin-value in $margin-padding-values {
	@each $direction-key, $direction-value in $margin-padding-directions {
		@if $direction-key== 'def' {
			$class-name: 'jbl-mg-#{$margin-value}';

			.#{$class-name} {
				margin: $margin-value * 1px;
			}
		} @else {
			$class-name: 'jbl-mg-#{$direction-key}-#{$margin-value}';

			.#{$class-name} {
				margin-#{$direction-value}: $margin-value * 1px;
			}
		}
	}
}

.jbl-margin-centered {
	margin: 0 auto;
}

// -------------------------- Resize Container --------------------------
.jbl-resize {
	height: 100%;
	width: 4px;
	position: absolute;
	cursor: col-resize;
	z-index: 100;
	right: 0;
}

.jbl-resize-inverted {
	@extend .jbl-resize;
	left: 0;
	right: inherit;
}

.jbl-no-border {
	border: none;
}

.jbl-page-container {
	flex-basis: 100%;
	padding: 24px;
	overflow-y: auto;
}

.jbl-grid-container {
	margin-bottom: 30px;
	border: 1px solid var(--cool-gray-10);
	border-radius: 4px;
	box-shadow: var(--shadow-block);
	background-color: var(--static-white);
}

.jbl-grid-container-header {
	padding: 12px 20px;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	border: 1px solid var(--cool-gray-10);
	transition: opacity 0.2s ease-out;
}

.jbl-grid-container-content {
	margin: 0 auto;
	padding: 20px;
	max-width: 100%;
	overflow-x: auto;
	border-top-right-radius: 3px;
	border-top-left-radius: 3px;
	transition: opacity 0.2s ease-out;
}

.jbl-actions,
.jbl-tile-actions {
	display: flex;
	align-items: center;
	gap: 8px;

	jbl-icon {
		cursor: pointer;
	}
}

.action-container {
	display: flex;
	flex-flow: row wrap;
	gap: 8px;

	button {
		margin-top: 16px;
		min-width: 120px;
	}

	jbl-icon,
	jbl-notification-icon {
		margin-top: 16px;
	}
}
